import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";
import ProjectImagesSwiper from "../../components/Website/ProjectImagesSwiper";
import ProjectImgWrapper from "../../components/Common/ImgWrapper";

const BroadwayApp = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Broadway Podcasts"
      description="Broadway Podcasts are experts in wine consultancy."
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="mb-5 bk-about-area">
            <Col md={6} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Broadway Podcasts</h2>
                  </div>
                  <ServicePellet service="UI & UX" />

                  <p class="wow move-up mb--20">
                    Broadway Podcast Network is all about creating an engaging,
                    immersive, user-friendly experience where theatre stories of
                    all kinds can be easily found, shared, and enjoyed. Broadway
                    Podcast Network is building the perfect hub that includes
                    tremendously talented content creators, all-star hosts,
                    producers, writers, industry leaders, and storytellers of
                    all kinds.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col md={6} xs={12}>
              <Img fluid={imagesData[15]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[0]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[1]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[2]} />
                </ProjectImgWrapper>
              </Col>
            </Row>
            <ProjectImagesSwiper
              imagesList={imagesData.filter((img, id) => id !== 15)}
            />
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[3]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[4]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[5]} />
                </ProjectImgWrapper>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[6]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[7]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[8]} />
                </ProjectImgWrapper>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[9]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[10]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[11]} />
                </ProjectImgWrapper>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-5 d-none d-md-flex">
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[12]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[13]} />
                </ProjectImgWrapper>
              </Col>
              <Col md={4} xs={12}>
                <ProjectImgWrapper>
                  <Img fluid={imagesData[14]} />
                </ProjectImgWrapper>
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="broadwayapp" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{
              name: "Houses To Share",
              link: "/work/housestoshare",
            }}
            prevProject={{ name: "Martins Wine", link: "/work/martinswine" }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default BroadwayApp;

export const BroadwayAppQuery = graphql`
  query BroadwayAppPhotos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/static/img/projects/broadwayapp/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
